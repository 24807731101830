<template>
  <div>
    <ion-header class="px-4 pt-2">
      <ion-toolbar class="pb-2">
        <ion-buttons slot="start">
          <ion-button @click="$ionic.modalController.dismiss()" class="w-8 h-8">
            <SvgIcon name="chevron-back-outline" />
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="gradient"></div>
    <ion-content class="ion-padding">
      <main class="px-4 py-4 pt-0">
        <div class="flex flex-col">
          <fieldset>
            <div class="mt-4 border-gray-200 divide-y divide-gray-200">
              <div v-for="(campaign, i) in campaigns" :key="i" class="flex flex-col items-start py-4">
                <div class="flex items-center h-5">
                  <span class="font-medium text-gray-700 select-none text-base capitalize">
                    {{ campaign.name.en }}
                  </span>
                </div>
                <div class="flex items-center text-sm ml-4 mt-1">
                  <ul class="list-disc">
                    <li
                      v-for="(tc, j) in campaign.tnc.en"
                      :key="j"
                      class="font-medium text-gray-500 select-none capitalize"
                    >
                      {{ tc }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </main>
    </ion-content>
  </div>
</template>

<script>
import "dayjs/locale/zh-cn";

export default {
  name: "PromoModal",
  props: {
    title: { type: String, default: "Promotions" },
    $router: { default: {} },
    self: { default: {} },
    campaigns: { default: {} }
  },
  data() {
    return {
      isLoading: false
    };
  },

  methods: {
    // get hasStoreDiscount() {
    //   let hasStorePromo = false;
    //   for (let i = 0; i < this.campaigns.length; i++) {
    //     if (this.campaigns[i].level == "STORE") {
    //       hasStorePromo = true;
    //       break;
    //     }
    //   }
    //   return hasStorePromo;
    // },
  }
};
</script>

<style lang="scss" scoped>
.gradient {
  height: 20px;
  position: relative;
  width: 100%;
  background: radial-gradient(ellipse farthest-side at top center, rgba(0, 0, 0, 0.06), transparent);

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02), transparent);
  }
}
</style>
